import React, { useState } from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FaCheckCircle, FaSquare } from "react-icons/fa";
import ReactPlayer from "react-player";
import { IoCloseCircle, IoSquare} from "react-icons/io5";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 664, min: 0 },
    items: 1,
  },
};

const Home = () => {
  const [playingVideo, setPlayingVideo] = useState(false);

  const PlayVideo = () => {
    console.log("Clicked");
    setPlayingVideo((prevState) => !prevState);
    // <ReactPlayer url="https://youtu.be/JQc9jpVQ0zU" />;
  };

  return (
    <div className="home-section">
      <video id="background-video" loop autoPlay muted>
        <source src="/videos/video-2.mp4" type="video/mp4" />
      </video>
      <div className="container home-container">
        <h1 className="home-text">Global Health Initiative </h1>
        <div className="home-logo">
          <img
            src="/images/logo-2.png"
            alt="logo"
            className="w-full h-full object-cover"
          />
        </div>
        <p className="home-text">First project proposals :</p>
        <p className="home-text">
          Dr APJ Abdul Kalam Global Health University & Center of Excellence
        </p>
        <p className="home-text">and</p>
        <p className="home-text">Khajuraho Global Health City</p>
      </div>
      <div className="home-body-container">
        <div className="home-body-container-inside">
          <div className=" three-cards-container">
            <div className="first-card">
              <h4 className="h1-text">OUR FOCUS</h4>
              <p className="p-text">
              Preventive Health Solutions for Cities
              </p>
              <div className="border-line" />
              <div className="intro-container">
                <p className="intro-text">
                The Global Health Initiative aims to generate through real live prototypes new, positive knowledge about global health. The prototypes are based on a unique concept: the Global Health City concept. This  concept consists of the five pillars:
                </p>
                 <div className="intro-text1">
                 <ul  type="square">
                    <li> <IoSquare/> Preventive perspective on health, </li>
                    <li> <IoSquare/>Projects for healthy and wealthy interaction of city and countryside,</li>
                    <li> <IoSquare/> Total stakeholder cooperation in identifying and executing projects,</li>
                    <li> <IoSquare/> Young citizens and young professionals as driving force,</li>
                    <li> <IoSquare/> Worldwide co-learning for new multidisciplinary and multicultural knowledge coordinated by a new university.</li>
                 </ul>

                 </div>
                
              </div>
            </div>
            <div className="second-card">
              <h4 className="h1-text">URGENT ISSUE</h4>
              <p className="p-text">global health after the pandemic</p>
              <div className="border-line" />
              <div className="intro-container">
                <p className="intro-text">
                  The Global Health Initiative aims to contribute to the highly
                  urgent issue of reaching global health after the pandemic.
                </p>
                

              </div>
            </div>
            <div className="third-card">
              <h4 className="h1-text">BACKGROUNDS OF THE INITIATIVE</h4>
              <p className="p-text">
                <br />
              </p>
              <div className="border-line" />
              <div className="intro-container">
              <p className="intro-text">
                  The initiative builds on the{" "}
                  <a href="https://www.euro.who.int/__data/assets/pdf_file/0009/101610/E67843.pdf" style={{color: "#fff"}}>
                    WHO healthy cities study
                  </a>
                  &nbsp; :  &nbsp; The initiating Foundation and the arise
                  of the initiative are described in LINK History foundation.
                </p>
              </div>
            </div>
          </div>
          {/* ---three-card ended--- */}
          <div className="container text-center middle-container-box">
            <Link to="/History-And-Track" className="link-p">
              <div className="story-about-us">
                <p>STORY ABOUT US</p>
              </div>
            </Link>
            <div className="title">
              <h2 className="title-text">
                Powered by the International Foundation for Global Knowledge
                Cities
              </h2>
            </div>
            <h2 className="sub-title">Invitation for cooperation</h2>
            <p className="intro-p-text">
              As initiating Foundation we invite public, private and scientific
              organisations and institutions in east and west which are
              interested to cooperate to contact us via{" "}
              <a className="p-link" href="#contact">
                LINK contact
              </a>
              .
              <br />
              Board International Foundation for Global Knowledge Cities
            </p>
            <h2 className="sub-title">Five-year plan and deliverables</h2>
            <p className="intro-p-text">
              The initiative has a duration of five years: 2021 – 2026, divided
              in three phases. The most important planned deliverables are the
              establishment of a new university for global health, a group of
              first generation prototypes and a network of young global health
              professionals.
            </p>
            <Link to="/" style={{ textDecoration: "none" }}>
              <p className="p-link" style={{ marginBottom: 0 }}>
                Know More
              </p>
            </Link>
          </div>
          {/* ---middle container finish -- */}
        </div>
        <div className="progress-container">
          <div className="inside-progress-container">
            <div className="inside-progress container">
              <div className="intro-section">
                <p className="intro-p-section">Unique vision and approach:</p>
                <h1 className="intro-h-section">
                  prototypes with youth leading the way
                </h1>
                <p style={{ color: "#ecd4d0" }}>
                  Our Foundation presents a unique vision with a proven ABCD
                  Roadmap to develop this new knowledge in prototypes. The
                  vision is founded on over a century experiences with
                  urban-rural interaction, local co-creation and co-learning.
                </p>
                <h1 className="intro-h-section">Our Vision</h1>
                <p style={{ color: "#ecd4d0" }}>
                  The approach is for local youth and young young professionals
                  (our thought creators) to develop worldwide co-learning
                  prototypes. As young people (the ‘next generation’) are the
                  end-users of the future, it is evident that they take the lead
                  and are at the forefront of answering the urgent issue of
                  global health after the pandemic. With our ABCD Roadmap this
                  can be realized.
                </p>
              </div>
              <div className="circular-progress">
                <CircularProgressbar
                  value="70"
                  text=" progress"
                  styles={buildStyles({
                    backgroundColor: "#fff",
                    pathColor: "#fff",
                    trailColor: "transparent",
                    textColor: "#fff",
                  })}
                  strokeWidth="4"
                />
              </div>
            </div>
          </div>
        </div>
        {/* --progress bar finished */}
        <div className="gallery">
          <div className="story-about-us-container">
            <Link to="/History-And-Track" className="link-p">
              <div className="story-about-us">
                <p>STORY ABOUT US</p>
              </div>
            </Link>
            <h2 className="gallery-heading">Gallery</h2>
          </div>
          <div className="gallery-carousal-container">
            <Carousel
              swipeable={true}
              draggable={true}
              responsive={responsive}
              infinite={true}
              autoPlaySpeed={5000}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              autoPlay={true}
            >
              <div className="gallery-card">
                <img
                  src="/images/img-7.jpg"
                  alt="img-7"
                  className="carousel-img"
                />
              </div>
              <div className="gallery-card">
                <img
                  src="/images/img-6.jpg"
                  alt="img-7"
                  className="carousel-img"
                />
              </div>
              <div className="gallery-card">
                <img
                  src="/images/g1.jpeg"
                  alt="img-7"
                  className="carousel-img"
                />
              </div>
              <div className="gallery-card">
                <img
                  src="/images/g2.jpeg"
                  alt="img-7"
                  className="carousel-img"
                />
              </div>
              <div className="gallery-card">
                <img
                  src="/images/rhine.jpg"
                  alt="img-7"
                  className="carousel-img"
                />
              </div>
            </Carousel>
          </div>
        </div>
        <div className="thought-container">
          <p className="thought-container-p">Global Health Initiative</p>
        </div>
        <div className="parallel-container">
          <div className="parallel-inside-container">
            <div className="parallel-img-container">
              <img
                src="/images/india.jpg"
                alt="india"
                className="parallel-img"
              />
            </div>
            <div className="parallel-intro-container">
              <h1 className="parallel-intro-heading">
                Starting the initiative:
              </h1>
              <h2 className="parallel-intro-sub-heading">
                India is launching platform
              </h2>
              <p>
                The Foundation consults with the Lead India Foundation the
                possible start in 2022 of the first two projects of the Global
                Health Cities initiative:
              </p>
              <div className="parallel-list-container">
                <div>
                  <FaCheckCircle className="parallel-list-icon" />
                </div>
                <p className="parallel-list-p">
                  The establishment in India of a new dedicated university: the
                  Dr. Kalam Global Health University & Center of Excellence.
                </p>
              </div>
              <div className="parallel-list-container">
                <div>
                  <FaCheckCircle className="parallel-list-icon" />
                </div>
                <p className="parallel-list-p">
                  The establishment also in India of the first prototype:
                  Khajuraho Global Health City.
                </p>
              </div>
              <p className="parallel-list-p">
                We think India is an excellent choice as the focal point because
                knowledge form east and west are both present and active and
                Health projects may deliver already on the short term innovative
                answers to the massive urbanisation, declining rural economy and
                large polluted rivers.
                <br></br>
                By naming of the new University and Center of Excellence to Dr.
                Kalam we honour his legacy about bringing awareness in youth
                contributing with high ambitions to a sustainable world.
              </p>
            </div>
          </div>
          <div className="parallel-inside-container">
            <div className="parallel-intro-container">
              <h1 className="parallel-intro-heading">October 15, 2021:</h1>
              <h2 className="parallel-intro-sub-heading">
                presentation of White paper
              </h2>
              <p>
                In October the Initiative may present itself to the world in
                cooperation with the Lead India Foundation at the celebration of
                Dr. Kalam’s 90th birthday. See LINK White paper Global Health
                University and first prototype. Of course the Foundation is open
                to look at options for prototypes in other countries too.
              </p>
            </div>
          </div>
          <div className="parallel-inside-container">
            <div className="parallel-intro-container">
              <h1 className="parallel-intro-heading">
                Role Foundation, history and track record
              </h1>
              <p>
                The role of the Global Knowledge Cities foundation is that of an
                independent expert catalyst, organiser and facilitator of the
                applications of the vision and the roadmap. It is not a project
                developer but assists all stakeholders in guarding the modern
                principles of the Global Knowledge Cities and putting youth
                driven health and wealth innovations as new inspiring and
                leading principle.
                <br></br>
                Why we, as Foundation, qualify to work successfully on the
                initiative is explained inHistory Foundation and Track Recordand
                see{" "}
                <a
                  href="/images/article-Global-Knowledge-Cities-concept.pdf"
                  className="publication-text"
                >
                  Publication
                </a>
                .
              </p>
            </div>
          </div>
        </div>
        {/* --parallel container finished ---- */}
        <div className="events-container">
          <div
            className="events-youtube--close-container"
            onClick={() => setPlayingVideo(false)}
          >
            {playingVideo && <IoCloseCircle className="close-class" />}
          </div>
          <div className="events-youtube-container">
            {playingVideo && (
              <ReactPlayer url="https://youtu.be/JQc9jpVQ0zU" controls={true} />
            )}
          </div>
          <div>
            <h1 className="gallery-heading">Events</h1>
          </div>
          <div className="events-gallery-container">
            {/* <div className="events-gallery-container-two"> */}
            <div className="eventer-gallery-one">
              <img
                src="/images/EventOne.jpeg"
                alt="event"
                className="event-one"
              />
            </div>
            <div className="event-gallery-two">
              {!playingVideo && (
                <div style={{ position: "relative" }}>
                  <div className="youtube-icon-container" onClick={PlayVideo}>
                    <img
                      src="/images/youtube.png"
                      alt="youtube"
                      className="event-one"
                    />
                  </div>
                  <img
                    src="https://img.youtube.com/vi/JQc9jpVQ0zU/0.jpg"
                    alt="event"
                    className="event-one"
                    style={{ height: 450 }}
                  />
                </div>
              )}
            </div>
            <div className="eventer-gallery-one">
              <img
                src="/images/eventtwo.jpg"
                alt="event"
                className="event-one"
              />
            </div>
          </div>

          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default Home;
