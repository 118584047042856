import React, { useState } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import { IoMenu, IoEllipsisVerticalSharp, IoCloseSharp } from "react-icons/io5";

const Header = () => {
  const [toggleBlock, setToggleBlock] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);

  return (
    <div className="col-12 header">
      <div className="header-container">
        <Link to="/">
          <div className="app-logo">
            <img src="/images/nlogo.png" alt="Global Health initiative" />
          </div>
        </Link>
        <div className="items-container">
          <Link
            to="/"
            style={{ textDecoration: "none" }}
            onClick={() => setToggleBlock(false)}
          >
            <p className="item  home">Home</p>
          </Link>
          <Link
            to="/Vision"
            style={{ textDecoration: "none" }}
            onClick={() => setToggleBlock(false)}
          >
            <p className="item">Our Vision</p>
          </Link>
          <Link
            to="/Roadmap"
            style={{ textDecoration: "none" }}
            onClick={() => setToggleBlock(false)}
          >
            <p className="item">Roadmap</p>
          </Link>
          <Link
            to="/initiator"
            style={{ textDecoration: "none" }}
            onClick={() => setToggleBlock(false)}
          >
            <p className="item">The initiators</p>
          </Link>
          <a
            href="#contact"
            style={{ textDecoration: "none" }}
            onClick={() => setToggleBlock(false)}
          >
            <p className="item">Contact</p>
          </a>
          <div
            className="three-dot-container"
            onClick={() => setToggleBlock((prevState) => !prevState)}
          >
            {toggleBlock ? (
              <IoCloseSharp className="three-dots" />
            ) : (
              <IoEllipsisVerticalSharp className="three-dots" />
            )}
          </div>
        </div>
        {toggleBlock && (
          <div className="small-nav-block">
            <Link
              to="/History-And-Track"
              style={{ textDecoration: "none" }}
              onClick={() => setToggleBlock(false)}
            >
              <p className="item">History foundation & track record</p>
            </Link>
            <div
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              style={{ textDecoration: "none", cursor: "pointer" }}
              onClick={() => setToggleBlock(false)}
            >
              <p className="item">First generation prototypes</p>
            </div>
            <Link
              to="/whitepaper"
              style={{ textDecoration: "none" }}
              onClick={() => setToggleBlock(false)}
            >
              <p className="item">
                White paper global health universities and proposal
              </p>
            </Link>
            <Link
              to="/Five-year-plan"
              style={{ textDecoration: "none" }}
              onClick={() => setToggleBlock(false)}
            >
              <p className="item">Five-year plan and deliverables</p>
            </Link>
          </div>
        )}
        <div
          className="menu-container"
          onClick={() => setMobileMenu((prevState) => !prevState)}
        >
          {mobileMenu ? (
            <IoCloseSharp className="three-dots" />
          ) : (
            <IoMenu className="three-dots" />
          )}
        </div>
      </div>
      <div>
        {mobileMenu && (
          <div>
            <div className="mobile-item-container">
              <Link
                to="/"
                style={{ textDecoration: "none" }}
                onClick={() => setMobileMenu(false)}
              >
                <p className="mobile-item  home">Home</p>
              </Link>
              <Link
                to="/Vision"
                style={{ textDecoration: "none" }}
                onClick={() => setMobileMenu(false)}
              >
                <p className="mobile-item">Our Vision</p>
              </Link>
              <Link
                to="/Roadmap"
                style={{ textDecoration: "none" }}
                onClick={() => setMobileMenu(false)}
              >
                <p className="mobile-item">Roadmap & Experience</p>
              </Link>
              <Link
                to="/initiator"
                style={{ textDecoration: "none" }}
                onClick={() => setMobileMenu(false)}
              >
                <p className="mobile-item">The initiators</p>
              </Link>
              <a
                href="#contact"
                style={{ textDecoration: "none" }}
                onClick={() => setMobileMenu(false)}
              >
                <p className="mobile-item">Contact</p>
              </a>
              <Link
                to="/History-And-Track"
                style={{ textDecoration: "none" }}
                onClick={() => setMobileMenu(false)}
              >
                <p className="mobile-item">History foundation & track record</p>
              </Link>
              <div
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                style={{ textDecoration: "none" }}
                onClick={() => setMobileMenu(false)}
              >
                <p className="mobile-item">First generation prototypes</p>
              </div>
              <Link
                to="/whitepaper"
                style={{ textDecoration: "none" }}
                onClick={() => setMobileMenu(false)}
              >
                <p className="mobile-item">
                  White paper global health universities and proposal
                </p>
              </Link>
              <Link
                to="/Five-year-plan"
                style={{ textDecoration: "none" }}
                onClick={() => setMobileMenu(false)}
              >
                <p className="mobile-item">Five-year plan and deliverables</p>
              </Link>
            </div>
          </div>
        )}
      </div>
      {/* modal */}
    </div>
  );
};

export default Header;
