import React from "react";
import "./HistoryFoundationAndTrackRecord.css";
import { IoStarHalf } from "react-icons/io5";
import { FaCheckCircle } from "react-icons/fa";

const HistoryFoundationAndTrackrecord = () => {
  return (
    <div className="history">
      {/* ---history section start --- */}
      <div>
        <div className="history-container">
          <div className="history-heading-container">
            <div className="sub-heading-container">
              <p className="sub-heading-p">
                <span className="">The International Foundation</span>
                &nbsp; for Global knowledge cities was founded in 2013 by{" "}
                <i> prof. Maurits van Rooijen </i> and{" "}
                <i> en Ir Ad de Rooij </i> after they published their study The
                Global Knowledge Cities concept.  see LINK{" "}
                <a
                  href="/images/article-Global-Knowledge-Cities-concept.pdf"
                  className="p-span-link"
                >
                  Publication
                </a>. The Foundation is a Public
                Benefit Organisation.
              </p>
            </div>
          </div>

          <div className="goal-of-foundation-container">
            <div className="foundation-internal-container">
              <h2 className="foundation-h-tag">Goal of the foundation</h2>
              <div>
                <p>
                  The general goal of the foundation is to develop global
                  knowledge using local experiments for integrated social,{" "}
                  <br></br>
                  economic and ecological solutions and create a global dialogue for co-learning. After publishing the concept
                   (see publication above) the foundation has been involved in three applications of the concept  <i>Khajuraho</i> (india),{" "}
                  <i>Schouwen-Duiveland</i> ( The Netherlands ) and the{" "}
                  <i>Rhine basin</i> ( Western Europe ). 
                    When in 2020 the pandemic started the Board of the Foundation decided to take the activities and 
                    the ambition to a next level: the Global Health Initiative.
                  
                </p>
              </div>
            </div>
            {/* --countries cards --- */}
            <div className="countries-card-container">
              <div className="countries-one-card-container">
                <div className="one-card-sub-container">
                  <div className="one-card-img-container">
                    <img
                      src="/images/khajuraho-india-temple.jpg"
                      alt="khajuraho"
                      className="one-card-img"
                    />
                  </div>
                  <div className="one-card-p">
                    <p>
                    The pilot case khajuraho reached in 2013 the status of memorandum of understanding between the foundation and the government of Madhya pradesh. Now this case has become in the Global Health Initiative one of the preferred first generation Global Health Cities projects
                    </p>
                  </div>
                </div>
              </div>
              <div className="countries-one-card-container">
                <div className="one-card-sub-container">
                  <div className="one-card-img-container">
                    <img
                      src="/images/img-3.jpg"
                      alt="netherland"
                      className="one-card-img"
                    />
                  </div>
                  <div className="one-card-p">
                    <p>
                      The pilot case Schouwen-Duiveland reached in 2018 the
                      status of active living lab, supported by public-private
                      cooperation. In the Living Lab youth, farmers, local
                      government , regional water board and knowledge
                      institutions experiment and learn together.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="countries-one-card-container card-one-c">
              <div className="one-card-sub-container">
                <div className="one-card-img-container">
                  <img
                    src="/images/rhine.jpg"
                    alt="rhine"
                    className="one-card-img"
                  />
                </div>
                <div className="one-card-p">
                  <p>
                    TThe pilot case of the Rhine basin reached in 2020 the
                    status of active international research project, connnecting
                    knowledge institutions in six countries and addressing the
                    challenge of local youth drive innovations in water, food,
                    energy and biodiversity.
                  </p>
                </div>
              </div>
            </div>
            {/* ---4 cards --- */}
            <div className="four-cards-one">
              <div className="four-cards-one-one">
                <div>
                  <IoStarHalf className="four-card-star" />
                </div>
                <div className="four-card-intro-container">
                  <p className="four-card-intro-container-p">
                    In 2018 Peof. Van Rooijen and Ad de Rooij presented the
                    first draft of the ABCD roadmap for global health city
                    prototypes at the high level round table smposium 'Water
                    Security and Food security : Agriculture is a thirsty
                    Business' in Lugano, switzerland. The model's objective is
                    to support the end-user co-creation in the pilots projects.
                  </p>
                </div>
              </div>
              <div className="four-cards-one-one">
                <div>
                  <IoStarHalf className="four-card-star" />
                </div>
                <div className="four-card-intro-container">
                  <p className="four-card-intro-container-p">
                    In fall 2020 the foundation presented in a
                    world-wide-webinar from lead india the proposal for a new
                    global health knowledge center: the Dr. Kalam Global Health
                    University.
                  </p>
                </div>
              </div>
            </div>
            <div className="four-cards-one">
              <div className="four-cards-one-one">
                <div>
                  <IoStarHalf className="four-card-star" />
                </div>
                <div className="four-card-intro-container">
                  <p className="four-card-intro-container-p">
                  When in early 2020 the covid 19 pandemic came and shocked the world, the foundation decided to start the Global Health Initiative and develop new knowledge on global health.
                  </p>
                </div>
              </div>
              <div className="four-cards-one-one">
                <div>
                  <IoStarHalf className="four-card-star" />
                </div>
                <div className="four-card-intro-container">
                  <p className="four-card-intro-container-p">
                    In spring 2021 the foundation was asked by water academy
                    sustainable and responsible development ( based in
                    switzerland ) to consider a possible engagement in a pre-G
                    20 forum on water in Rome, Autumn 2021, to be held on
                    invitation of the italian prime minsister, with a follow up
                    in 2022 when india will be the G20 host.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* --track record-- */}
      <div id="track">
        <div className="track-container">
          <div>
            <div>
              <h1 className="track-record-heading">Track Record ...</h1>
            </div>
            <div className="track-record-underline" />
          </div>
          <div>
            <div className="track-record-header">
              <div className="track-header-img-container">
                <img
                  src="/images/track-record.jpg"
                  className="track-header-img"
                  alt="track record"
                />
              </div>
              <div className="track-header-intro-container">
                <p>
                  The ABCD roadmap for Global Health Cities prototypes is based
                  upon a unique concept for multi party cooperation that has
                  been developed and successfully applied by the Dutch Ministry
                  of Infrastructure and Water Management between 1993 en
                  2007.Co-founder of the Foundation Ad de Rooij led this program
                  for the Ministry as a project director together with hundreds
                  of experts.
                </p>
                <p>
                  The program realized numerous innovations and innovative
                  national infrstructural and spatial projects, always with the
                  end-users as driving force. This approach was revolutionary
                  because the usual approach started with politicians and
                  experts giving their solution. The broad experiences and
                  comprehensive new knowledge have been recorded by over one
                  hundred authors in two books (in Dutch), downloadable from
                  <a
                    href="https://www.procesmetselaars.nl/"
                    target="_blank"
                    rel="noreferrer"
                    className="p-span-link"
                  >
                    www.procesmetselaars.nl/
                  </a>
                  .
                </p>
              </div>
            </div>
            {/* --content-- */}
            <div className="content-container">
              <div className="heading-list-container">
                <h1 className="heading-list-heading">
                  Some examples of national projects where the end-users led
                  successful the way are :
                </h1>
                <div className="lists-heading">
                  <div className="head-list">
                    <div>
                      <FaCheckCircle className="check-list" />
                    </div>
                    <p className="head-list-p">
                      Extending Rotterdam harbour into the sea;
                    </p>
                  </div>
                  <div className="head-list">
                    <div>
                      <FaCheckCircle className="check-list" />
                    </div>
                    <p className="head-list-p">
                      Improving waterquality in national lakes;
                    </p>
                  </div>
                  <div className="head-list">
                    <div>
                      <FaCheckCircle className="check-list" />
                    </div>
                    <p className="head-list-p">
                      Public-private-societal cocreation and decision making for
                      the A4 sustainable highway;
                    </p>
                  </div>
                  <div className="head-list">
                    <div>
                      <FaCheckCircle className="check-list" />
                    </div>
                    <p className="head-list-p">
                      Enlarging the drain capacity of the river Rhine through
                      public-private-societal cocreation and decision making;
                    </p>
                  </div>
                  <div className="head-list">
                    <div>
                      <FaCheckCircle className="check-list" />
                    </div>
                    <p className="head-list-p">
                      Connecting citizens with administrators for the river Waal
                    </p>
                  </div>
                </div>
              </div>
              {/* - */}
              <div className="heading-list-container">
                <h1 className="heading-list-heading">
                  Some examples of local projects where the Foundation is one of
                  the co-founders and ABCD roadmap is applied are :
                </h1>
                <div className="lists-heading">
                  <div className="head-list">
                    <div>
                      <FaCheckCircle className="check-list" />
                    </div>
                    <p className="head-list-p">
                      Youth, farmers, local government and knowledge
                      institutions cocreating innovations for water, food and
                      health on the island of Schouwen-Duiveland, see
                      <a
                        href="https://livinglabschouwen-duiveland.nl/"
                        rel="noreferrer"
                        target="_blank"
                        className="p-span-link"
                      >
                        https://livinglabschouwen-duiveland.nl/
                      </a>
                    </p>
                  </div>
                  <div className="head-list">
                    <div>
                      <FaCheckCircle className="check-list" />
                    </div>
                    <p className="head-list-p">
                      Local citizen driven cocreation for the sustainable
                      development of the Broekpolder Vlaardingen, The
                      Netherlands, see{" "}
                      <a
                        href="https://www.broekpolder-vlaardingen.nl/"
                        target="_blank"
                        rel="noreferrer"
                        className="p-span-link"
                      >
                        https://www.broekpolder-vlaardingen.nl/
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              {/* - */}
              <div className="heading-list-container">
                <h1 className="heading-list-heading">
                  A project that recently started and applies (with help of the
                  Foundation) the ABCD method with youth as driving force :
                </h1>
                <div className="lists-heading">
                  <div className="head-list">
                    <div>
                      <FaCheckCircle className="check-list" />
                    </div>
                    <p className="head-list-p">
                      Youth, knowledge institutions, administrators and policy
                      makes developing multiple youth-drive innovations in the
                      Rhine basin. The project has been adopted by the Unesco
                      IHE university with support of the international Rhine
                      commission and the Dutch Ministry for Infrastructure and
                      Watermanagement see{" "}
                      <a
                        href="https://www.youthfortherhine.org/"
                        target="_blank"
                        rel="noreferrer"
                        className="p-span-link"
                      >
                        https://www.youthfortherhine.org/
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryFoundationAndTrackrecord;
