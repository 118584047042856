import React from "react";
import "./Footer.css";
import { IoLocationOutline, IoMailOutline } from "react-icons/io5";

const Footer = () => {
  return (
    <div id="contact" className="footer-container">
      <div className="contact-container">
        <div className="contact-heading-container">
          <h2 className="contact-h2-heading">CONTACT US</h2>
          <div className="contact-bottom-line" />
        </div>
        <div className="map-address">
          <a
            href="https://www.google.co.in/maps/place/EuroStar+Inn/@24.8395316,79.9188026,17z/data=!4m8!3m7!1s0x3982e5f8e926d185:0x68227eed95fb6fa8!5m2!4m1!1i2!8m2!3d24.8395267!4d79.9209913"
            target="_blank"
            rel="noreferrer"
          >
            <div className="map-address-img">
              <img
                src="/images/in.png"
                alt="map"
                className="map-address-img-internal"
              />
            </div>
          </a>
          <div className="address-container">
            <div className="address-container-text">
              {/* <div> */}
              <IoLocationOutline className="address-container-icon" />
              {/* </div> */}
              <p className="address-container-p">
                Communication Office, Global Health City <br></br> Khajuraho ,
                Madhya Pradesh
              </p>
            </div>
            <div className="address-container-text sec-text">
              {/* <div> */}
              <IoMailOutline className="address-container-icon" />
              {/* </div> */}
              <p className="address-container-p">
              <a href="mailto:info@globalheathinitiative.info">info@globalheathinitiative.info</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <p style={{ textAlign: "center", color: "#fff" }}>
        All Right Reserved @ OneWonLabs Pvt. Ltd.
      </p>
    </div>
  );
};

export default Footer;
