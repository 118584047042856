import React from "react";
import "./App.css";
import Header from "./client/common/Header";
import Home from "./client/components/Home/Home";
import Whitepaper from "./client/components/whitepaper/Whitepaper";
import Vision from "./client/components/Vision/vision";
import Roadmap from "./client/components/RoadmapAndEperience/Roadmap";
import HistoryFoundationAndTrackrecord from "./client/components/HistoryFoundationAndTrackRecord/HistoryFoundationAndTrackrecord";
import Fiveyearplan from "./client/components/FivYearPlan/Fiveyearplan";
import Footer from "./client/common/Footer";
import Initiator from "./client/components/Initiator/Initiator";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route path="/initiator">
          <Initiator />
        </Route>
        <Route path="/Five-year-plan">
          <Fiveyearplan />
        </Route>
        <Route path="/History-And-Track">
          <HistoryFoundationAndTrackrecord />
        </Route>
        <Route path="/Roadmap">
          <Roadmap />
        </Route>
        <Route path="/Vision">
          <Vision />
        </Route>
        <Route path="/whitepaper">
          <Whitepaper />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
      <Footer />
      {/* ---modal--- */}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog global-modal modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                First generation prototypes
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              “The wish is to start the Global Health Initiative with three
              prototypes for healthy living for individuals and communities
              which are situated in different parts of the world. Possibly USA,
              Europe and Asia.<br></br>
              At this moment the Foundation is exploring two concrete locations
              as possible first generation prototypes for healthy living for
              individuals and communities. One locations is in India (Khajuraho)
              and the other in Germany (Berlin-Brandenburg). If the local and
              national governments of these locations would agree in 2022 these
              locations could become two of the desired three prototypes.
              <br></br>
              Why chosen for Khajuraho? Khajuraho was ten years ago for the
              International Foundation for Global Knowledge Cities the virtual
              prototype for the development of the Global Knowledge Cities
              concept. Also Khajuraho has an intranational airport and many
              hotels so reachable from abroad. Also it is a UNESCO World
              Heritage Site because of a group of temples visited by many
              western and eastern visitors. They see sculptures expressing the
              encountering of the male and female energies. So Khajuraho could
              in more than in one way become a perfect location to organize the
              meeting and merging of global health knowledge from east and west.
              <br></br>
              And why Berlin-Brandenburg? Because of the fit with the preparing
              work by two local initiators of sustainable area development for a
              region south of Berlin-Brandenburg: Golßen – Drahnsdorf. This
              region has a history in food production and until now the bigger
              enterprises work in that field so the multidisciplinary topic
              ‘food, water and health’ could specify local innovations. The role
              of youth and young professionals can be organized with help of a
              Berlin based university. We are in discussion with the University
              of Europe for Applied Sciences to maybe take this role.”
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
