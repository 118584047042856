import React from "react";
import "./Roadmap.css";
import { FaCheckCircle } from "react-icons/fa";

const Roadmap = () => {
  return (
    <div className="container roadmap">
      <div>
        <div className="first-container">
          <h1 className="first-container-ho">
            The concept of the cooperation of societal energies
          </h1>
          <p className="first-container-p-roadmap">
            Our ABCD roadmap for Global Health Cities prototypes is very
            practical and based on a large number of successful projects in The
            Netherlands in the domain of national infrastructure .(
            <a href={"/History-And-Track" + "#track"}>
              see LINK Track Record
            </a>
            )<br></br>
            The concept that underlies the roadmap is a unique view on societal
            innovation and public decision making: the cooperation of societal
            energies. This concept is in fact an analogy of the classical
            philosophical theory of the elements. This theory is described in
            classical philosophical works of classical works of numorous Western
            and Eastern philosophical scholars.
            <br></br>
            According to the theory the elements earth, water, fire and air
            permanently create reality. Applying this hypothesis to the subject
            of societal innovation the ABCD method arose: a structure for four
            basic societal energies creating societal innovation.
            <br></br>
            It is essential to note that in the method - as both Western and
            Eastern philosophers postulated - actual reality is seen as chaotic
            per definition. By analyzing situations with the societal energies
            perspective the observer can see a fictive, dynamic image of
            creative chaos that has the potential to develop to the next state:
            creative order (the cooperation of the four societal energies).
          </p>
        </div>
        <div>
          <h1 className="first-container-ho">
            The following scheme gives for each of the four cocietal energies
            the physical actors and their natural contributions:
          </h1>
          <div className="table-container">
            <tr className="tr-table">
              <th className="tr-one" style={{ flex: 0.6 }}>
                Societal Energy
              </th>
              <th className="tr-one" style={{ flex: 0.2 }}>
                Actors
              </th>
              <th className="tr-one" style={{ flex: 0.2 }}>
                Natural Contribution
              </th>
            </tr>
            <div>
              <ul className="table-ul">
                <ul style={{ flex: 0.6 }}>
                  <li style={{ color: "#1c162d" }} className="ul-li-o">
                    <span style={{ color: "#ef3f49" }}>A -</span> Administrative
                    and political
                  </li>
                </ul>
                <li style={{ flex: 0.2, display: "flex" }} className="ul-li-t">
                  leaders
                </li>
                <li style={{ flex: 0.2, display: "flex" }} className="ul-li-t">
                  direction & perspective
                </li>
              </ul>
              <ul className="table-ul">
                <ul style={{ flex: 0.6 }}>
                  <li style={{ color: "#1c162d" }} className="ul-li-o">
                    <span style={{ color: "#ef3f49" }}>B -</span> Business and
                    finance
                  </li>
                </ul>
                <li style={{ flex: 0.2, display: "flex" }} className="ul-li-t">
                  enterpreneurs, bankers
                </li>
                <li style={{ flex: 0.2, display: "flex" }} className="ul-li-t">
                  solutions including finance
                </li>
              </ul>
              <ul className="table-ul">
                <ul style={{ flex: 0.6 }}>
                  <li style={{ color: "#1c162d" }} className="ul-li-o">
                    <span style={{ color: "#ef3f49" }}>C -</span> Civil services
                    and knowledge
                  </li>
                </ul>
                <li style={{ flex: 0.2, display: "flex" }} className="ul-li-t">
                  helpers, scientists
                </li>
                <li style={{ flex: 0.2, display: "flex" }} className="ul-li-t">
                  process and knowledge
                </li>
              </ul>
              <ul className="table-ul">
                <ul style={{ flex: 0.6 }}>
                  <li style={{ color: "#1c162d" }} className="ul-li-o">
                    <span style={{ color: "#ef3f49" }}>D -</span> Demos
                  </li>
                </ul>
                <li style={{ flex: 0.2, display: "flex" }} className="ul-li-t">
                  end users
                </li>
                <li style={{ flex: 0.2, display: "flex" }} className="ul-li-t">
                  problems and wishes
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <h1 className="first-container-ho">
            The general robust conclusion which resulted out of all the
            applications is:
          </h1>
          <div className="roadmap-middle-part">
            <i>
              When in a complex societal situation all four basic societal
              energies A, B, C and D manifest themselves starting with the D and
              according to their own nature, and a fusion of their contributions
              takes place, the outcome is new knowledge and innovations.
            </i>
          </div>
        </div>
        <div>
          <h1 className="first-container-ho">
            The four steps of the ABCD roadmap
          </h1>
          <h1 style={{ fontWeight: 700 }}>The four steps are :</h1>
          <p className="last-roadmap-container-first-p">
            Through four steps Global Health Cities prototypes can be designed
            and exploratory studied with youth as driving force. Youth are the
            end-users of the future. So they need to lead at the forefront of
            the multidisciplinary health and wealth innovations for sustainable
            human settlements we are looking for!
          </p>
          <div className="last-container-lists">
            <div className="last-container-list-cont">
              <div>
                <FaCheckCircle className="roadmap-checkmark" />
              </div>
              <p>
                The letter D: the voice of youth: young people giving in a
                certain local situation their multidisciplinary views on actual
                problems and possible solutions for the fields of sustainable
                urban-rural development, local economy, water, food and nature;
                all in relation to prevention. Output step 1: wishes and demands
                for the Global Health Cities prototype.
              </p>
            </div>
            <div className="last-container-list-cont">
              <div>
                <FaCheckCircle className="roadmap-checkmark" />
              </div>
              <p>
                The letters A, B and C: the concrete views and contributions of
                A, B and C, all in direct relation to the result of step 1. The
                contributions for A, B and C are collected by a dialogue of
                knowledge from east and west. The output of step 2 is the
                content of the letters B, C and D.
              </p>
            </div>
            <div className="last-container-list-cont">
              <div>
                <FaCheckCircle className="roadmap-checkmark" />
              </div>
              <p>
                Synergy of the ABCD’s: a fusion between all four letters needs
                creative dialogues between representiatives of all four letters.
                The output of step 3 is a design for the Global Health Cities
                prototype consisting of desired local health and wealth
                experiments and of a group of individuals and organisations
                prepared to carry out the experiments.
              </p>
            </div>
            <div className="last-container-list-cont">
              <div>
                <FaCheckCircle className="roadmap-checkmark" />
              </div>
              <p>
                Choice by the letter A for specific experiments and actions for
                exploratory research on the Global Health Cities prototype. The
                output of step 4 is local administrative decision including
                finance and a start of the exploratory research.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
