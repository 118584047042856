import React from "react";
import "./Initiator.css";

const Initiator = () => {
  return (
    <div className="initiator">
      <div className="initiator-header">
        <h2 className="initiator-header-h2">The Initiator</h2>
        <div>
          <p style={{ color: "#fff" }}>
            sustainable prevention in human settlements, youth as driving force
          </p>
        </div>
      </div>
      {/* --------- */}
      <div className="initiator-card">
        <div style={{ flex: 1 }}>
          <h1 className="initiator-name">bio Maurits van Rooijen</h1>
          <div className="img-upper-section">
            <div className="initiator-intro">
              <p>
                Prof.{" "}
                <strong>
                  <i>Dr Maurits van Rooijen</i>
                </strong>{" "}
                (Breda, the Netherlands 1956) is together with Ad de Rooij
                co-founder and co-creator of the Global Health Initiative. He
                studied economics history with a doctorate in Geography at
                Utrecht University, where he also held his first academic
                position. He has published widely in green and sustainable
                urbanisation and was visiting professor at universities across
                the world.
              </p>
              <p>
                He has held senior administrative and managerial positions at
                the Erasmus University Rotterdam, University of Westminster
                London, Victoria University Melbourne, Leiden University
                Netherlands and more recently CEO and rector magnificus of
                Nyenrode Business University. He is currently the Chief Academic
                Officer of Global University Systems, a private sector provider
                operating some twenty universities around the world, rector of
                the University of Europe for Applied Sciences, Germany, board
                chairman of GISMA Germany, board chairman IBAT Dublin Ireland
                etc.
              </p>
              <p>
                He has served as chairman of several international organisations
                such as the Compostela Group of Universities (Santiago de
                Compostela Spain) and the World Association for Cooperative and
                Work Integrated Education (WACE). He was awarded ao the EAIE
                Constance Meldrum Prize for Vision and Leadership and the Golden
                Medal of the University of Santiago de Compostela.
              </p>
            </div>
            <div>
              <div className="img-internal-section">
                <img
                  src="/images/img-5.jpg"
                  alt="bio Maurits van Rooijen"
                  className="initiator-img"
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="social-icons-initiator-container">
          <div className="social-icons-initiator">
            <img
              src="images/twitter.jpg"
              alt="twitter"
              className="social-icons-img"
            />
          </div>
          <div className="social-icons-initiator">
            <img
              src="/images/youtube.png"
              alt="youtube"
              className="social-icons-img"
            />
          </div>
        </div> */}
      </div>
      {/* --s */}
      <div className="initiator-card">
        <div style={{ flex: 1 }}>
          <h1 className="initiator-name">Brief bio Ad de Rooij</h1>
          <div className="img-upper-section">
            <div className="initiator-intro">
              <p>
                Prof.{" "}
                <strong>
                  <i>Ad de Rooij</i>
                </strong>{" "}
                (Vlaardingen, The Netherlands 1953) is together with Maurits van
                Rooijen co-founder and co-creator of the Global Health
                Initiative. He is also member of the Advisory Board Youth for
                the Rhine and initiator and advisor of several innovative
                projects with citizens participation in The Netherlands.
              </p>
              <p>
                Previously Ad has been the creator and for more than ten years
                the director of the department of innovation and cocreation
                within the Dutch Ministry of Infrastructure and Water
                Management. This department introduced a break through approach
                for complex projects positioning the end-users as driving force.
                Thereby creating a bridge between government and citizens in
                public decision making. Ad has led and assisted the application
                of the approach in numerous national infrastructural and spatial
                projects. On this topic he has (co-)written and published
                several articles and books (in Dutch).
              </p>
            </div>
            <div>
              <div className="img-internal-section">
                <img
                  src="/images/img-5.jpg"
                  alt="bio Maurits van Rooijen"
                  className="initiator-img"
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="social-icons-initiator-container">
          <div className="social-icons-initiator">
            <img
              src="images/twitter.jpg"
              alt="twitter"
              className="social-icons-img"
            />
          </div>
          <div className="social-icons-initiator">
            <img
              src="/images/youtube.png"
              alt="youtube"
              className="social-icons-img"
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Initiator;
