import React from "react";
import "./vision.css";
import { FaCheckCircle } from "react-icons/fa";

const Vision = () => {
  return (
    <div>
      <div className="vision-header">
        <h2 className="vision-header-h2">Our Vision</h2>
        <div>
          <p style={{ color: "#fff" }}>
            sustainable prevention in human settlements, youth as driving force
          </p>
        </div>
      </div>
      <div className="body-container">
        <div className="sub-body-container">
          <div className="vision-img-container">
            <img src="/images/img-9.jpg" alt="sfs" className="vision-image" />
          </div>
          <div className="vision-intro-container">
            <div className="first-intro">
              <h1 className="first-intro-h">
                Urbanisation in relation to health of individuals and
                communities has been a huge problem over centuries worldwide.
              </h1>
              <div className="first-sub-intro">
                <div className="first-sub-intro-list">
                  <div>
                    <FaCheckCircle className="checkmark" />
                  </div>
                  <p>
                    Because it creates in the mega cities unhealthy and
                    environmentally catastrophic concentrations of people whilst
                    impoverishing the countryside, depriving smaller communities
                    of their young people.
                  </p>
                </div>
                <div className="first-sub-intro-list">
                  <div>
                    <FaCheckCircle className="checkmark" />
                  </div>
                  <p>
                    And because it impoverishes the social, cultural and
                    economic foundations required for a sustainable and healthy
                    rural society.
                  </p>
                </div>
              </div>
            </div>
            <div className="sec">
              <div className="sec-p-container">
                <p className="sec-p">
                  Merging conditions found in large cities with a healthy, more
                  ecologically sound and harmonious society in the surrounding
                  countryside has been the holy grail of urbanisation for over a
                  century.
                </p>
              </div>
              <div className="sec-p-container">
                <p className="sec-p">
                  Promising solutions like the Garden Cities and New Town
                  movements have been applied but were constrained by the
                  acceptable physical distance to the large cities (typically
                  30-60 minutes by train or road) linked to the traditional
                  dependency on manufacturing and movement of produce and
                  products.
                </p>
              </div>
            </div>
            <div className="first-intro">
              <h1 className="first-intro-h">
                In the 21st century new parameters have emerged for the future
                of urbanization in relation to health :
              </h1>
              <div className="first-sub-intro">
                <div className="first-sub-intro-list">
                  <div>
                    <FaCheckCircle className="checkmark" />
                  </div>
                  <p>
                    The Covid 19 pandemic has put the issue of health worldwide
                    as a top priority for research for individual and communal
                    resilience and finding preventive strategies. This leading
                    to a reinforced understanding of ecological sustainability
                    and the central role of health and well-being.
                  </p>
                </div>
                <div className="first-sub-intro-list">
                  <div>
                    <FaCheckCircle className="checkmark" />
                  </div>
                  <p>
                    The emergence of the knowledge sector and central role of
                    education and innovation for wealth that has replaced the
                    dominant dependency on manufacturing;
                  </p>
                </div>
                <div className="first-sub-intro-list">
                  <div>
                    <FaCheckCircle className="checkmark" />
                  </div>
                  <p>
                    The digital communications as well as aviation and
                    super-fast trains have redefined the physical realities,
                    allowing for long distance linkages, even global linkages
                    breaking away from the old train and car constraints.
                  </p>
                </div>
              </div>
            </div>
            <div className="first-intro">
              <h1 className="first-intro-h">
                The new realities help us to redefine the principles underneath
                the management and growth of human settlements :
              </h1>
              <div className="first-sub-intro">
                <div className="first-sub-intro-list">
                  <div>
                    <FaCheckCircle className="checkmark" />
                  </div>
                  <p>
                    It remains important for the sake of harmonious development
                    of societies to have a credible counterbalance to big city
                    centric urbanisation, but the creation of new settlements
                    and the adaptation of existing settlements are no longer
                    geographically limited. The big advantage of this is that
                    creation of (new) hubs can be on much low priced land as
                    long as the imperative of high speed connectivity is
                    addressed. The healthy human settlements thus can operate
                    within a national and global network.
                  </p>
                </div>
                <div className="first-sub-intro-list">
                  <div>
                    <FaCheckCircle className="checkmark" />
                  </div>
                  <p>
                    Rather than factories/manufacturing ‘knowledge and
                    innovation’ will need to be at the heart of such
                    developments in order to develop a local wealth which
                    favours the whole of the local community
                  </p>
                </div>
                <div className="first-sub-intro-list">
                  <div>
                    <FaCheckCircle className="checkmark" />
                  </div>
                  <p>
                    More advanced insights in health with a focus at prevention,
                    wealth, wellbeing and environmental sustainability should be
                    developed and incorporated in a defining manner.
                  </p>
                </div>
              </div>
            </div>
            <div className="sec">
              <div className="sec-p-container">
                <p className="sec-p">
                  To take full advantage of these 21st century realities we
                  coined the term Global Health Cities - a global network of
                  cities that embrace the principles of decentralised healthy
                  urbanisation as mentioned above.
                </p>
              </div>
              <div className="sec-p-container">
                <p className="sec-p">
                  As we know from previous decentralised urbanisation
                  projects,the success is not just determined by physical
                  planning and economic stimulus, but equally by community
                  building.Community building and social innovation are to be at
                  the heart of decentralised healthy urbanisation. In the last
                  century this was organized top-down and rather
                  paternalistic.Therefore we integrated the ABCD method in the
                  Global Knowledge Cities concept to address and organize
                  cocreation effectively.
                </p>
              </div>
            </div>
            <div className="first-intro">
              <h1 className="first-intro-h">
                A distinctive key feature of the ABCD method is end-user driven
                co-creation: accepting that the big principles will be agreed
                and fixed but that the roles of all stakeholders are based on
                co-creation by the ABCD’s with the end-users as driving force:
              </h1>
              <div className="first-sub-intro">
                <div className="first-sub-intro-list">
                  <div>
                    <p className="checkmark-t">A</p>
                  </div>
                  <p>for Administration,</p>
                </div>
                <div className="first-sub-intro-list">
                  <div>
                    <p className="checkmark-t">B</p>
                  </div>
                  <p>for Businesses,</p>
                </div>
                <div className="first-sub-intro-list">
                  <div>
                    <p className="checkmark-t">C</p>
                  </div>
                  <p>for Civil service and Knowledge institutions and</p>
                </div>
                <div className="first-sub-intro-list">
                  <div>
                    <p className="checkmark-t">D</p>
                  </div>
                  <p>
                    for Demos, or better: Devoted citizens being the end-users.
                  </p>
                </div>
              </div>
            </div>
            <div className="sec">
              <div className="sec-p-container">
                <p className="sec-p">
                  In dynamic healthy urbanisation, as this is an investment in
                  future generations, co-creation by the ABCD’s is preferably
                  focused on younger generations taking the part of the letter
                  D. This ABCD method has proven to stimulate innovation, rather
                  than replicating established concepts of living, and ensure
                  buy-in of the younger generations who would otherwise be
                  tempted to seek their fortune in the big cities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vision;
