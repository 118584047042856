import React from "react";
import "./Whitepaper.css";

const Whitepaper = () => {
  return (
    <div className="container whitepaper">
      <div className="whitepaper-container">
        <h1 className="whitepaper-h1">WHITE PAPER</h1>

        <h2 className="whitepaper-h2">
        Dr APJ Abdul Kalam Global Health University and Center of Excellence
        </h2>

        <p className="whitepaper-p-tag">
        Prof Manuel Freire, Prof Maurits van Rooijen and Ir Ad de Rooij
          <br></br>
          On behalf of the Global Health Initiative
          <br></br>
          October 2021
        </p>
        <h3 className="h3-heading">Urgency and context</h3>
        <p className="p-tag-two">
        The COVID pandemic also raises an urgent question about the post pandemic
era: How to reach global health after the pandemic? The available (western)
medical and pharmaceutical knowledge is of course able to come up with
relevant answers. Still, in addition to these answers, it is also important to
approach the question from new and multidisciplinary perspectives.
        </p>
        <h3 className="h3-heading">The Global Health Initiative</h3>
        <p className="p-tag-two">
          One innovative perspective is to look at health at the local level and
          from the pro-active perspective. The Global Health Initiative has
          decided to use this perspective in order to develop in the next five
          years new knowledge. The five- year period starts October 15 2021 with
          the launch of the website{" "}
          <a href="https://www.globalhealthinitiative.info/">
            www.GlobalHealthInitiative.info
          </a>
          .<br></br>
          The motto of the initiative is:
          <br></br>
          <span className="whitepaper-subhead">
            <i>'Healthy communities creating healthy wealth'</i>
          </span>
          <br></br>
          Health is seen by the Initiative as consisting of multiple elements at
          interconnected levels: the body and mind of individuals, the local
          community, the society and their ecological and economic surroundings.
          Where the classical community committed robbery of the rural and
          natural surroundings, the healthy community creates in harmony with
          the surrounding healthy wealth.
          <br></br>
          The innovative and complex nature of this opinion on health makes it
          necessary to adapt a new type of research and new education
          organizations for global health. They need to be integrated into a
          global context, sharing international quality standards but also need
          to adapt to the singularities of each country. Besides training new
          professionals in the multidisciplinary approach, these schools have to
          become epicenters that spread global health knowledge to all sectors
          of society, facilitating its socio-economic development.
        </p>
        <h3 className="h3-heading ">
          Global health education in the near future
        </h3>
        <p className="p-tag-two">
          The foregoing implies a growing demand for multidisciplinary global
          health professionals worldwide, who must acquire a degree of training
          and specialization to attend pro-active global health strategies
          following new paradigms, both from a scientific and organizational
          point of view.
          <br></br>
          Smart cities and healthy communities become ideal places for the
          implosion of knowledge and the implementation and scaling of new
          initiatives from an embryonic phase. Their results are more visible on
          a global scale, becoming a model for other communities.
          <br></br>
          In the same way, smart cities and healthy communities can be an engine
          for promoting global health education and strategies at a global
          scale. The concept of Global Health Universities inside them can be a
          helpful tool, starting from India.
        </p>
        <h3 className="h3-heading">India</h3>
        <p className="p-tag-two">
          India is presently in a state of economic, demographic, and
          epidemiological transition in terms of healthcare. The country is
          developing strategies in different areas, including reducing health
          inequalities, establishing an agenda of communicable and
          non-communicable diseases, promoting healthy lifestyles, or preparing
          measures to control epidemic and pandemic emergencies caused by new
          pathogens.
          <br></br>
          The achievement of these challenges is of great importance in
          establishing future public health models of global impact. India can
          easily get it since medical professionals&#39; medical education and
          competence level acquire global relevance. That partly explains why
          India is the largest exporter of healthcare professionals, including
          more than 70,000 medical graduates working in the USA, UK, Europe, and
          the other countries.
          <br></br>
          Global Health Universities can be important actors in this process,
          thanks to their new pro-active perspective, multidisciplinary
          knowledge, innovative vocation, potential international visibility and
          ability to implement ideas internationally through other smart cities
          and healthy communities.
          <br></br>
          Global Health Universities can facilitate disruptive educational
          models that allow training more qualified to suit the new roles posed
          by the future global health strategies. The Global Knowledge Cities
          concept, which was inspired by the Garden Cities concept, includes a
          proven end-user centered roadmap for the cocreation for prototypes. In
          the Initiative this roadmap will be used to position young citizens,
          being the end-users of the future, at the forefront of developing
          learning technologies and new global pro-active health paradigms.
          <br></br>
          But the differentiating element of Global Health Universities is its
          ability to generate ideas and transmit knowledge, which contributes to
          developing and managing pro-actively global health policies in
          different countries, which translates into a higher level of economic
          and social wealth.
        </p>
        <h3 className="h3-heading ">Proposal in cooperation with Lead India</h3>
        <p className="p-tag-two">
          The Global Health Initiative proposes in cooperation with Lead India
          the establishment of a new Global Health University &amp; Center of
          Excellence. In October 2020 the Foundation Lead India organized a
          world-wide webinar to honor the 89 th birthday of the former president
          of India, Dr Kalam. In the vision of Dr Kalam India and the world
          needs ambitious goals and youth taking responsible roles for
          sustainability and health. The Global Health Initiative, at that time
          in preparation, had the honor to contribute in the webinar and
          proposed the idea of a new Global Health University &amp; Center of
          Excellence. This should be named after Dr Kalam and be established in
          India. In the webinar both Lead India and the Indian Ministry of
          Health reacted positively. This is why the Global Health Initiative
          now cooperates with the Foundation Lead India in the preparation in
          2021 and 2022 of concrete projects.
          
        </p>
      </div>
    </div>
  );
};

export default Whitepaper;
