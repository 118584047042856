import React, { useState } from "react";
import "./Fiveyearplan.css";
import { FaCheckCircle } from "react-icons/fa";

const Fiveyearplan = () => {
  const [activeStateOne, setActiveStateOne] = useState(true);
  const [activeStateTwo, setActiveStateTwo] = useState(false);
  const [activeStateThree, setActiveStateThree] = useState(false);

  const handleDateOne = () => {
    setActiveStateOne(true);
    setActiveStateTwo(false);
    setActiveStateThree(false);
  };

  const handleDateTwo = () => {
    setActiveStateOne(false);
    setActiveStateTwo(true);
    setActiveStateThree(false);
  };

  const handleDateThree = () => {
    setActiveStateOne(false);
    setActiveStateTwo(false);
    setActiveStateThree(true);
  };

  return (
    <div className="five-year-plan">
      <div className="five-year-data-container">
        <div className="five-year-date-container">
          <div
            className={activeStateOne ? "year-one" : "year"}
            onClick={() => handleDateOne()}
          >
            <p className="year-p">2021 - 2022</p>
          </div>
          <div
            className={activeStateTwo ? "year-one" : "year"}
            onClick={() => handleDateTwo()}
          >
            <p className="year-p">2021 - 2022</p>
          </div>
          <div
            className={activeStateThree ? "year-one" : "year"}
            onClick={() => handleDateThree()}
          >
            <p className="year-p">2021 - 2022</p>
          </div>
        </div>
        {activeStateOne && (
          <div className="five-year-intro-container">
            <div className="five-year-intro-one">
              <h2 style={{ fontSize: 20 }}>
                <span className="five-span">Phase 1 :</span> preparing the start
              </h2>
              <p style={{ marginBottom: 0, color: "red" }}>
                <span className="five-goal">Goals :</span> to improve the
                initiative in cooperation with public, private and knowledge
                organisations and institutions.
              </p>
              <h2 className="five-span">Deliverables phase 1:</h2>
              <div className="five-list-container">
                <div className="five-year-list-container">
                  <div>
                    <FaCheckCircle className="fiveyear-checkmark" />
                  </div>
                  <p>Website as basis for information and communication</p>
                </div>
                <div className="five-year-list-container">
                  <div>
                    <FaCheckCircle className="fiveyear-checkmark" />
                  </div>
                  <p>
                    White paper Global Health Universities and proposal for Dr
                    APJ Abdul Kalam Global Health University & Center of
                    Excellence;
                  </p>
                </div>
                <div className="five-year-list-container">
                  <div>
                    <FaCheckCircle className="fiveyear-checkmark" />
                  </div>
                  <p>
                    Project plans for the first-generation prototypes (for
                    instance in India, Germany and USA);
                  </p>
                </div>
                <div className="five-year-list-container">
                  <div>
                    <FaCheckCircle className="fiveyear-checkmark" />
                  </div>
                  <p>
                    Project organisation and business plan for Dr APJ Abdul
                    Kalam Global Health University & Center of Excellence.
                    Present status: in preparation is together with LEAD India
                    the launch of the White paper on October 15 2021.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* -- */}
        {activeStateTwo && (
          <div className="five-year-intro-container">
            <div className="five-year-intro-one">
              <h2 style={{ fontSize: 20 }}>
                <span className="five-span">Phase 2 :</span> research and
                knowledge development
              </h2>
              <p style={{ marginBottom: 0, color: "red" }}>
                <span className="five-goal">Goals :</span> to establish the new
                university and to realise the first generation prototypes
              </p>
              <h2 className="five-span">Deliverables phase 2 :</h2>
              <div className="five-list-container">
                <div className="five-year-list-container">
                  <div>
                    <FaCheckCircle className="fiveyear-checkmark" />
                  </div>
                  <p>
                    Lessons from the first generation prototypes by implementing
                    the ABCD Roadmap
                  </p>
                </div>
                <div className="five-year-list-container">
                  <div>
                    <FaCheckCircle className="fiveyear-checkmark" />
                  </div>
                  <p>Establishing the Dr Kalam Global Health University</p>
                </div>
                <div className="five-year-list-container">
                  <div>
                    <FaCheckCircle className="fiveyear-checkmark" />
                  </div>
                  <p>Network of young global health professionals.</p>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* -- */}
        {activeStateThree && (
          <div className="five-year-intro-container">
            <div className="five-year-intro-one">
              <h2 style={{ fontSize: 20 }}>
                <span className="five-span">Phase 3 :</span> results and outcome
              </h2>
              <p style={{ marginBottom: 0, color: "red" }}>
                <span className="five-goal">Goals :</span> harvesting and ending
                the initiative.
              </p>
              <h2 className="five-span">Deliverables phase 3:</h2>
              <div className="five-list-container">
                <div className="five-year-list-container">
                  <div>
                    <FaCheckCircle className="fiveyear-checkmark" />
                  </div>
                  <p>Evaluation of the prototypes and the university</p>
                </div>
                <div className="five-year-list-container">
                  <div>
                    <FaCheckCircle className="fiveyear-checkmark" />
                  </div>
                  <p>Final conference; end of the initiative.</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Fiveyearplan;
